<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" class="gap-x-1" :gutter="8" justify="end">
            <el-button
              type="default"
              v-if="!!selectedItems.length"
              @click="handlePrintSelectedCashierRequests"
            >
              Imprimir {{ selectedItems.length }} pedido(s)
            </el-button>
            <el-dropdown
              @command="(c) => (requestStatus = c) | fetchRequests()"
              size="medium"
              trigger="click"
            >
              <el-button type="primary" size="medium">
                Filtrar por: {{ RequestStatus[requestStatus] || ""
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <div
                    v-for="(label, status) in RequestStatus"
                    :key="label + status"
                  >
                    <el-dropdown-item :command="status">
                      <li
                        :class="{
                          'el-dropdown-menu__item': true,
                          'no-padding': true,
                          active: requestStatus === status,
                        }"
                      >
                        {{ label }}
                      </li>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              type="primary"
              size="medium"
              @click="() => openProductRequestModal(null)"
              >CADASTRAR</el-button
            >
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      select-on-indeterminate
      :cell-style="() => 'text-align:center;'"
      :data="Requests"
      style="width: 100%; z-index: 0"
      @selection-change="handleSelectItems"
    >
      <el-table-column type="selection" :selectable="selectable" width="60" />
      <el-table-column prop="name" label="nome"></el-table-column>
      <el-table-column prop="firm.name" label="açougue"></el-table-column>
      <el-table-column
        prop="createdBy.name"
        label="responsável"
      ></el-table-column>
      <el-table-column
        label="cadastrado em"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>
      <el-table-column label="urgencia" prop="urgency">
        <template #default="r">
          <div
            :class="{
              'is-negative': r.row.urgency === 'high',
              'is-medium': r.row.urgency === 'medium',
              'is-positive': r.row.urgency === 'low',
            }"
          >
            {{ Urgency[r.row.urgency] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="products.length"
        label="produtos"
      ></el-table-column>
      <el-table-column label="ações" :min-width="150">
        <template #default="{ row: productRequest }">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                v-if="
                  ['ready'].includes(requestStatus) &&
                  canAny('CanAcceptRequest')
                "
                @confirm="updateStatus(productRequest, 'delivered')"
                title="
              Finalizar entrega?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-check"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="
                  ['created', 'canceled'].includes(requestStatus) &&
                  canAny('CanAcceptRequest')
                "
                @confirm="updateStatus(productRequest, 'accepted')"
                title="
              Aceitar pedido?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-check"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="
                  ['accepted'].includes(requestStatus) &&
                  canAny('CanAcceptRequest')
                "
                @confirm="updateStatus(productRequest, 'ready')"
                title="
              Enviar para entrega?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-check"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="
                  ['created'].includes(requestStatus) &&
                  canAny('CanRejectRequest')
                "
                @confirm="updateStatus(productRequest, 'canceled')"
                title="
              Recusar pedido?
            "
                ><template #reference>
                  <el-button onlyIcon type="warning" circle>
                    <i class="el-icon-close"></i></el-button></template
              ></el-popconfirm>

              <el-button
                v-if="['accepted'].includes(requestStatus)"
                onlyIcon
                type="primary"
                circle
                @click="printRequest(productRequest)"
              >
                <i class="el-icon-printer"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openProductRequestModal(productRequest)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-popconfirm
                v-if="canAny('CanDeleteRequest')"
                @confirm="deleteRequest(productRequest)"
                title="
              Remover pedido de compra?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="requests?.lastPage"
    >
    </el-pagination>
    <product-request-modal
      :showModal="showProductRequestModal"
      :request="request"
      @close-modal="showProductRequestModal = false"
      @should-update="fetchRequests"
    ></product-request-modal>
  </el-card>
</template>

<script>
import ProductRequestModal from "./modals/ProductRequestModal.vue";
import RequestService from "../services/requests";
import SystemService from "../services/system";
import { generatePDF } from "../services/reports";
import { notifyError, notifySuccess } from "../utils/notifiers";

export default {
  name: "CashierRequests",
  components: {
    ProductRequestModal,
  },
  data: () => ({
    requestStatus: "created",
    showProductRequestModal: false,
    requests: null,
    currentPage: 1,
    request: null,
    isLoading: false,
    selectedItems: [],
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchRequests();
  },
  computed: {
    Urgency() {
      return {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
      };
    },
    Requests() {
      return this.requests || [];
    },
    Can() {
      return this.$store.state.auth.can;
    },
    RequestStatus() {
      return {
        created: "Aberto",
        accepted: "Em processo de compra",
        canceled: "Recusado",
        ready: "Disponível pra entrega",
        delivered: "Entregue",
      };
    },
  },
  watch: {
    currentPage() {
      this.fetchRequests();
    },
  },
  methods: {
    getProductsList(){
      const products = this.selectedItems.map(r => r.products.map(p => ({...p, request: r}))).flat();
      const productsById = products.reduce((total, pRequest) => {
        const key = pRequest.product.uid;
        if(!total[key]) total[key] = [];

        total[key].push(pRequest);
        return total;
      }, {})

      return Object.values(productsById);
    },
    sumTotalOfProducts(products){
      return products?.reduce((total, product) => total + Number(product.quantity), 0)
    },
    sortAlphabetically(products){
      return products?.sort(([a], [b]) => a.product?.name > b.product?.name ? 1 : -1)
    },
    getRequestsByFirm(){
      const requestsByFirm = new Map();
      this.requests?.forEach(r => {
        if(requestsByFirm.has(r.firm?.name)){
          requestsByFirm.get(r.firm?.name).push(r);
          return;
        }

        requestsByFirm.set(r.firm?.name, [r]);
      });
      return requestsByFirm
    },
    getHeaders(){
      const firmNames = this.getRequestsByFirm().keys();
      return [
        [
          "PRODUTO",
          ...firmNames,
          "QTD TOTAL"
        ]
      ]
    },
    getContent(){
      const requestsByFirm = this.getRequestsByFirm();
      const firmNames = Array.from(requestsByFirm.keys());
      const firmQuantities = (product) => {
        const a = firmNames.map((k) => requestsByFirm.get(k)?.flatMap(r => r?.products).reduce((total, r) => r.product?.uid === product?.uid ? total + Number(r.quantity) : total, 0) || 0);
        return a;
      }
      return this.sortAlphabetically(this.getProductsList()).map(p => [
        p[0]?.product?.name,
        ...firmQuantities(p[0]?.product),
        this.sumTotalOfProducts(p)
      ])
    },
    handlePrintSelectedCashierRequests() {
      const content = this.getContent();

      generatePDF({
        fileName: "Pedidos de Compras",
        content,
        headerMessage: "Relação de Produtos",
        defaultFontSize: 8,
        headers: this.getHeaders()
      })
    },
    handleSelectItems(items) {
      this.selectedItems = items;
    },
    printRequest(productRequest) {
      if (this.$store.state.user.printer) {
        SystemService()
          .Printers(this.$store.state.user.printer.uid)
          .Job()
          .create({
            layout: "orders",
            print_content: JSON.stringify({
              code: "S/N",
              created_at: productRequest.created_at,
              deliver_at: new Date().toISOString(),
              client: productRequest.firm.name,
              products: productRequest.products.map((p) => ({
                name: p.name,
                quantity: p.quantity,
                price: p.price,
              })),
            }),
          })
          .then(() => {
            this.$message.success("Impressão enviada para a impressora");
          });
      }
    },
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    resetPagination() {
      this.currentPage = 1;
    },
    sendToDelivery(closing) {
      if (closing)
        this.updateRequest({
          uid: closing.uid,
          status: "ready",
        });
    },
    updateStatus(request, status) {
      if (request) {
        request.status = status;
        this.updateRequest(request);
      }
    },
    openProductRequestModal(request) {
      this.request = request;
      this.showProductRequestModal = true;
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    async deleteRequest(r) {
      if (!r) return;

      const { error } = await RequestService(r.uid).delete();

      if (error) {
        notifyError("Não foi possível remover esse pedido");
      } else {
        notifySuccess("Pedido removido com sucesso");
      }

      this.fetchRequests();
    },
    async updateRequest(r) {
      if (!r) return;

      const { error } = await RequestService(r.uid).update(r);

      if (error) {
        notifyError("Ocorreu um erro ao atualizar o pedido");
      } else {
        notifySuccess("Pedido atualizado com sucesso");
        this.fetchRequests();
      }
    },
    async fetchRequests() {
      this.isLoading = true;

      const requestFilter = {
        status: this.requestStatus,
      };

      if (!this.canAny("CanSeeAllRequests")) {
        requestFilter.createdBy = this.$store.state.user.uid;
      }

      const { requests } = await RequestService().index(requestFilter);

      if (requests) {
        this.requests = requests;
      }

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.gap-x-1 {
  column-gap: 0.5rem;
}
.is-medium {
  color: #f90;
}
.is-negative {
  color: #f00;
}
.is-positive {
  color: #0f0;
}
</style>
